import { useRef, useState } from 'react';

import { type DtoPageSection } from '@lp-lib/api-service-client/public';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from '../../../src/components/icons/Arrows';
import { useSSRLayoutEffect } from '../hooks/useSSRLayoutEffect';
import { PageSectionIcon } from './PageSectionIcon';

function PageSectionMenu(props: {
  section: DtoPageSection;
  selected: boolean;
  onClick: () => void;
}) {
  const { section, selected, onClick } = props;

  return (
    <button
      type='button'
      onClick={onClick}
      className={`
        flex-none h-full px-4
        flex items-center gap-2.5
        ${selected ? 'bg-secondary' : ''} hover:bg-lp-gray-002
        transition-opacity
      `}
    >
      <PageSectionIcon
        section={section}
        className='w-6 h-6 object-contain fill-current'
      />
      <p className='text-sms whitespace-nowrap'>{section.title}</p>
    </button>
  );
}

function Carousel(props: { children: React.ReactNode[]; gapWidth: number }) {
  const { children, gapWidth } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [prevVisible, setPrevVisible] = useState(false);
  const [nextVisible, setNextVisible] = useState(true);

  const prev = () => {
    if (!containerRef.current || !contentRef.current) return;

    let sum = 0;
    for (let i = currentIndex - 1; i >= 0; i--) {
      sum += contentRef.current.children[i].clientWidth;
      if (sum > containerRef.current.clientWidth) {
        setCurrentIndex(i + 1);
        return;
      }
      sum += gapWidth;
    }
    setCurrentIndex(0);
  };

  const next = () => {
    if (!containerRef.current || !contentRef.current) return;

    let sum = 0;
    for (let i = currentIndex; i < children.length; i++) {
      sum += contentRef.current.children[i].clientWidth;
      if (sum > containerRef.current.clientWidth) {
        setCurrentIndex(i);
        return;
      }
      sum += gapWidth;
    }
    setCurrentIndex(children.length - 1);
  };

  // TODO: subscribe to resize event
  useSSRLayoutEffect(() => {
    if (!containerRef.current || !contentRef.current) return;

    let currentOffset = 0;
    for (let i = 0; i < currentIndex; i++) {
      currentOffset += contentRef.current.children[i].clientWidth + gapWidth;
    }

    let width = 0;
    for (let i = currentIndex; i < children.length; i++) {
      width += contentRef.current.children[i].clientWidth;
    }
    width += gapWidth * (children.length - currentIndex - 1);

    setCurrentOffset(currentOffset);
    setPrevVisible(currentIndex > 0);
    setNextVisible(width > containerRef.current.clientWidth);
  }, [currentIndex]);

  return (
    <div className='w-full h-full flex items-center gap-2.5'>
      <button
        type='button'
        onClick={prev}
        className={`
          flex-none btn
          w-5 h-5 bg-lp-black-004 rounded-full 
          text-secondary hover:text-white
          border border-secondary
          flex justify-center items-center
          ${prevVisible ? 'opacity-100' : 'opacity-0'}
        `}
      >
        <ArrowLeftIcon className='w-2 h-2 fill-current' />
      </button>

      <div className='flex-1 h-full overflow-hidden' ref={containerRef}>
        <div
          className='h-full flex items-center transition-transform ease-in-out duration-200'
          style={{
            transform: `translateX(-${currentOffset}px)`,
            gap: gapWidth,
          }}
          ref={contentRef}
        >
          {props.children}
        </div>
      </div>

      <button
        type='button'
        onClick={next}
        className={`
          flex-none btn 
          w-5 h-5 bg-lp-black-004 rounded-full
          text-secondary hover:text-white
          border border-secondary
          flex justify-center items-center
          ${nextVisible ? 'opacity-100' : 'opacity-0'}
        `}
      >
        <ArrowRightIcon className='w-2 h-2 fill-current' />
      </button>
    </div>
  );
}

export function PageSectionNav(props: {
  sections: DtoPageSection[];
  onSectionClick: (section: DtoPageSection, index: number) => void;
  selectedSection?: DtoPageSection | null;
}) {
  const { sections } = props;

  const showSections = sections.filter((section) => !section.hideFromNav);

  if (showSections.length < 2) return null;

  return (
    <Carousel gapWidth={20}>
      {showSections.map((section, index) => (
        <PageSectionMenu
          section={section}
          key={section.id}
          selected={section.id === props.selectedSection?.id}
          onClick={() => props.onSectionClick(section, index)}
        />
      ))}
    </Carousel>
  );
}
